import React from "react";
import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { gql, useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import useOverviewContext from "../../store/use-overview-context";
import "../../../../../../lib/string-helpers";

/**
 * Component for rendering a tag select input.
 * @param {Object} props - The component props.
 * @param {Array} props.tags - The array of tags to render.
 * @param {Function} props.onChange - The function to call when a tag is selected.
 * @param {Array} props.values - The currently selected tag values.
 * @returns {JSX.Element} The rendered TagSelect component.
 */
const TagSelect = ({ tags, onChange, values }) => {
  return (
    <>
      {tags.map((tag) => (
        <span
          key={tag.id}
          onClick={() => onChange(tag.id)}
          className={classNames({
            active: values.includes(tag.id),
          })}
        >
          {tag.label}
        </span>
      ))}
    </>
  );
};

TagSelect.propTypes = {
  tags: PropTypes.array,
  onChange: PropTypes.func,
  values: PropTypes.array,
};

/**
 * Component for rendering a taxonomy filter.
 * @param {Object} props - The component props.
 * @param {Object} props.item - The item object containing filter options.
 * @returns {JSX.Element} The rendered TaxonomyFilter component.
 */
const TaxonomyFilter = ({ item }) => {
  const { setFieldValue, values } = useFormikContext();
  const { setDiscipline, discipline } = useOverviewContext();

  const TAXONOMY_QUERY = gql`
    query TaxonomyQuery($vid: String = "tags") {
      entityQuery(
        entityType: TAXONOMY_TERM
        filter: { conditions: [{ field: "vid", value: [$vid] }] }
        sort: { field: "weight", direction: ASC }
        limit: 100
      ) {
        items {
          label
          id
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(TAXONOMY_QUERY, {
    variables: {
      vid: item.options.vid,
    },
  });

  if (
    (item.options.id === "field_interior_filter_target_id" &&
      !discipline.includes("interior")) ||
    (item.options.id === "field_region_target_id" &&
      !discipline.includes("architecture")) ||
    (item.options.id === "field_design_filter_target_id" &&
      !discipline.includes("design"))
  ) {
    return;
  }

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-8 offset-md-1">
            <h3>
              <FormattedMessage id="error.general" />
            </h3>
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Change the tags to be displayed capitalized or uppercased.
   */
  let items = [];
  if (data) {
    if (item.options.vid === "discipline") {
      items = data.entityQuery.items.map((item) => ({
        ...item,
        label: item.label.toUpperCase()
      }));
    } else {
      items = data.entityQuery.items.map((item) => ({
        ...item,
        label: item.label.capitalize()
      }));
    }
  }

  // filter out selected projects
  items.filter(item => item.label.toLowerCase() !== "selected projects")

  /**
   * Handles the change event when a tag is selected or deselected.
   * @param {string} newValue - The ID of the selected tag.
   */
  const handleChange = (newValue) => {
    // Get current values or set to empty array.
    let _values = values[item.options.id] || [];

    // If the value is already in the array, remove it, otherwise add it.
    if (_values.includes(newValue)) {
      _values = _values.filter((value) => value !== newValue);
    } else {
      _values = [..._values, newValue];
    }

    // Set the discipline in the overview context.
    if (item.options.id === "field_discipline_target_id") {
      setDiscipline(
        _values
          .map((value) => {
            const item = data?.entityQuery.items.find(
              (item) => item.id === value
            );
            return item ? item.label.toLowerCase() : null;
          })
          .filter(Boolean)
      );
    }

    // Set the new values in Formik.
    setFieldValue(item.options.id, _values);
  };

  const selectField = () => {
    return (
      <TagSelect
        tags={items}
        onChange={handleChange}
        values={values[item.options.id] || []}
      />
    );
  };

  return (
    <div className="taxonomy-filter">
      <Field name={item.options.id} component={selectField} />
    </div>
  );
};

TaxonomyFilter.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.shape({
      id: PropTypes.string,
      vid: PropTypes.string,
      expose: PropTypes.shape({
        label: PropTypes.string,
        placeholder: PropTypes.string,
        multiple: PropTypes.bool,
      }),
    }),
  }),
};

export default TaxonomyFilter;
