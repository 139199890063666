import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import Image from "../../../general-components/image/image";
import { restHostBackend } from "../../../config";

const ProjectIntro = ({ content, introText }) => {
  const sliderSettings = {
    autoplay: false,
    autoplaySpeed: 5000,
    easing: "ease",
    speed: 1000,
    adaptiveHeight: false,
    centerMode: true,
    variableWidth: true,
    infinite: true,
  };
  return (
    <section className="paragraph paragraph-project-intro">
      <Slider {...sliderSettings}>
        {content.fieldImages.map((image, i) => (
          <Fragment key={i}>
            {image.__typename === "MediaBild" && (
              <Image data={image.fieldMediaImage} />
            )}
            {image.__typename === "MediaVideo" && (
              <video
                src={restHostBackend + image.mediaFileUrl?.path}
                autoPlay
                controls={false}
                loop
                muted
              />
            )}
          </Fragment>
        ))}
      </Slider>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h1>{content.title}</h1>
            <span className="subheader">{content.fieldSubheader}</span>
            <span className="location">{content.fieldCity}</span>
          </div>
          <div
            className="col-12 col-lg-6 text"
            dangerouslySetInnerHTML={{ __html: introText }}
          ></div>
        </div>
      </div>
    </section>
  );
};

ProjectIntro.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    fieldSubheader: PropTypes.string,
    fieldImages: PropTypes.array,
  }),
  introText: PropTypes.string,
};

export default ProjectIntro;
