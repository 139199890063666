import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Project from "../project";
import { useHistory } from "react-router-dom";
import { LockBodyScroll } from "../../../hooks/useLockBodyScroll";
import { motion } from "framer-motion";
import LanguageSwitch from "../../../i18n/language-switch";
import LogoButton from "../../../general-components/logo-button";

const Overlay = () => {
  let history = useHistory();

  let back = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  useEffect(() => {
    document.body.classList.add("overlay-open");
    return () => document.body.classList.remove("overlay-open");
  });

  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <LockBodyScroll />
      <div className="overlay-header">
        <div className="container d-flex justify-content-center justify-content-lg-between">
          <div className="logo-wrapper d-none d-lg-block">
            <LogoButton />
          </div>
          <button className="btn btn-close" onClick={back}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_2)">
                <path d="M1 1L17 17" stroke="white" stroke-width="2" />
                <path d="M1 17L17 1" stroke="white" stroke-width="2" />
              </g>
              <defs>
                <clipPath id="clip0_1_2">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <LanguageSwitch className="d-none d-lg-flex" />
        </div>
      </div>
      <Project />
    </motion.div>,
    document.getElementById("overlay-root")
  );
};

Overlay.propTypes = {};

export default Overlay;
