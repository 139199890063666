import { useState, useEffect } from "react";

const useHistoryState = () => {
  const [historyState, setHistoryState] = useState(typeof window !== "undefined" && window.history.state);

  useEffect(() => {
    const handleStateChange = () => {
      setHistoryState(window.history.state);
    };

    // Original pushState und replaceState Methoden speichern
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    // pushState überschreiben
    window.history.pushState = function (state, title, url) {
      originalPushState.apply(window.history, arguments);
      handleStateChange();
    };

    // replaceState überschreiben
    window.history.replaceState = function (state, title, url) {
      originalReplaceState.apply(window.history, arguments);
      handleStateChange();
    };

    window.addEventListener("popstate", handleStateChange);

    return () => {
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
      window.removeEventListener("popstate", handleStateChange);
    };
  }, []);

  return historyState;
};

export default useHistoryState;