import React from "react";
import { self } from "../config";
import { useSelector } from "react-redux";

const LogoButton = () => {
  const currentLanguage = useSelector(
    (reduxStore) => reduxStore.i18n.currentLanguage
  );

  let root = "/";
  if (currentLanguage === "en") {
    root = "/en";
  }

  return (
    <a className="site-logo-button" href={root}>
      <img src={self + "/logo.svg"} />
    </a>
  );
};

LogoButton.propTypes = {};

export default LogoButton;
